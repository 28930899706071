window._ = require('lodash');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.Utils = require('./utils');

window.tempusDominus = require('@eonasdan/tempus-dominus');

window.Webcam = require('webcam-easy').default;

window.RecordRTC = require('recordrtc');
window.WaveSurfer = require('wavesurfer.js');
window.WaveSurfer.microphone = require('wavesurfer.js/dist/plugin/wavesurfer.microphone');
window.Sortable = require('sortablejs').Sortable;
